import React from "react";
import LpDemo from "../../../page-components/LpDemo";
import { lineOfBusiness } from "../../../constants";

export default function DemoPageWebPayments() {
  return (
    <LpDemo
      leadType="lead-gen"
      dataLayerEventNameAppendix="-lead"
      formSubHeader="Save money, simplify operations, and own your guest data, with the all-in-one mobile &amp; online ordering platform."
      formId="7de61c6d-4419-4cfe-9fcd-113dc1602eff"
      useShortForm
      thankYouPath="/lp/online-ordering/thank-you"
      phoneNumber="+13035240930"
      phoneText="(303) 524-0930"
      imageName="oo-lp.png"
      lineOfBusiness={lineOfBusiness.HOSPITALITY}
    />
  );
}
